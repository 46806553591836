<template>
  <div id="user-list">
    <!-- app drawer:add -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :status-options="statusOptions"
      @refetch-data="fetchUsers"
    ></user-list-add-new>

    <!-- app drawer:edit -->
    <user-list-edit
      v-model="isEditUserSidebarActive"
      :role-options="roleOptions"
      :status-options="statusOptions"
      :selected-user="selectedUser"
      @refetch-data="fetchUsers"
      v-if="isEditUserSidebarActive"
    ></user-list-edit>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Users List
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            rounded
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add User</span>
          </v-btn>

          <v-btn
            color="secondary"
            rounded
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :options.sync="options"
        :server-items-length="totalUserListTable"
        :loading="loading"
        show-select
      >
        <!-- name -->
        <template #[`item.names`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.names) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.names }}
              </router-link>
              <small>{{item.email.split('@')[0]}}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.roles`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.roles[0]['name'])"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.roles[0]['name'])}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.roles[0]['name'])"
              >
                {{ resolveUserRoleIcon(item.roles[0]['name']) }}
              </v-icon>
            </v-avatar>
            <span class="">{{ toTitleCase(item.roles[0]['name']) }}</span>
          </div>
        </template>

        <!-- last Access -->
        <template #[`item.last_logon`]="{item}">
          <span
            v-if="item.last_logon"
          >{{item.last_logon}}</span>
          <span
            v-else
            class="text-capitalize"
          >NEVER ACCESSED</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status['name'])"
            :class="`${resolveUserStatusVariant(item.status['name'])}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ toTitleCase(item.status['name']) }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <!-- @click.stop="isEditUserSidebarActive = !isEditUserSidebarActive" -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="secondary"
                @click.stop="()=> handleEdit(item)"
              >
                <v-icon>
                  {{ icons.mdiPlaylistEdit }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'apps-user-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>View User</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                    color="danger"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete User</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPlaylistEdit,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import UserListAddNew from './UserListAddNew.vue'
import UserListEdit from './UserListEdit.vue'
import userStoreModule from '../userStoreModule'

import useUsersList from './useUsersList'
import textTransform from '@/mixins/textTransform'

export default {
  components: {
    UserListAddNew,
    UserListEdit,
  },
  mixins: [textTransform],
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'appUser'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,

      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)
    const isEditUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: '1' },
      { title: 'Editor', value: '3' },
      { title: 'Normal User', value: '5' },
    ]

    const statusOptions = [
      { title: 'Pending', value: '4' },
      { title: 'Active', value: '1' },
      { title: 'Inactive', value: '3' },
    ]

    return {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalUserListTable,
      roleOptions,
      statusOptions,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      isEditUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,
      selectedUser: {},

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPlaylistEdit,
      },
    }
  },
  methods: {
    handleEdit(item) {
      this.isEditUserSidebarActive = !this.isEditUserSidebarActive
      this.selectedUser = item
      store.commit('appUser/addSelectedUser', item)
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

<template>
  <v-navigation-drawer
    :value="isEditUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-edit-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Edit User</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-edit-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <div class="text-center mb-5">
            <v-avatar
              class="v-avatar-light-bg primary--text mb-1"
              size="128"
            >
              <v-img
                v-if="inlineData.avatar"
                :src="require(`@/assets/images/avatars/${inlineData.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(inlineData.names) }}</span>
            </v-avatar>
            <br/>
            <v-icon size="22">
              {{ icons.mdiCookieEdit }}
            </v-icon>
            <a href="#"> Choose Icon</a>
          </div>

          <v-text-field
            v-model="userData.first_name"
            outlined
            dense
            :rules="[validators.required]"
            label="First Name"
            placeholder="John"
            hide-details="auto"
            class="mb-6"
            autocomplete="nope"
          ></v-text-field>

          <v-text-field
            v-model="userData.last_name"
            :rules="[validators.required]"
            outlined
            dense
            label="Last Name"
            placeholder="Doe"
            hide-details="auto"
            class="mb-6"
            autocomplete="nope"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required,validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
            autocomplete="nope"
          ></v-text-field>

          <v-select
            v-model="userData.role_id"
            :rules="[validators.required]"
            label="User Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-select
            v-model="userData.user_status_id"
            :rules="[validators.required]"
            label="Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            rounded
          >
            Update
          </v-btn>
          <v-btn
            color="error"
            outlined
            rounded
            type="button"
            @click="$emit('update:is-edit-user-sidebar-active',false)"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCookieEdit } from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'

export default {
  model: {
    prop: 'isEditUserSidebarActive',
    event: 'update:is-edit-user-sidebar-active',
  },
  props: {
    isEditUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = useUsersList()
    const blankUserData = {
      first_name: props.selectedUser.first_name,
      last_name: props.selectedUser.last_name,
      email: props.selectedUser.email,
      role_id: props.selectedUser.roles[0]['id'],
      user_status_id: props.selectedUser.status['id'],
    }

    const inlineUserData = {
      id: props.selectedUser.id,
      names: props.selectedUser.names,
      avatar: props.selectedUser.avatar,
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const inlineData = ref(JSON.parse(JSON.stringify(inlineUserData)))

    const onSubmit = () => {
      if (valid.value) {
        const data = {
          id: inlineUserData.id,
          userData: userData.value,
        }

        store
          .dispatch('appUser/editUser', data)
          .then(() => {
            emit('refetch-data')
            emit('update:is-edit-user-sidebar-active', false)
          })
          .catch(() => {
            emit('refetch-data')
            emit('update:is-edit-user-sidebar-active', false)
            console.log('There was an error')
          })
      } else {
        validate()
      }
    }

    return {
      form,
      onSubmit,
      userData,
      inlineData,
      valid,
      validate,
      avatarText,
      loading,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiCookieEdit,
      },
    }
  },
}
</script>
